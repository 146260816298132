import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const Success = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth, maxHeight } = React.useContext(DimensionContext);

  const onPress = () => {
    navigation.navigate("SignIn");
  };

  return (
    <View style={[styles.bodyStyle, { height: maxHeight * 0.5}]}>
      <Image
        source={{uri: images.bg_transparent}}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <Image
        source={{uri: images["gamezone-logo-horizontal"]}}
        style={styles.smallLogoContainer}
        resizeMode="stretch"
      />
      <View style={styles.v_center}>
        <Image
          resizeMode="contain"
          source={{uri: images["text-success"]}}
          style={styles.i_success}
        />
        <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.013 }]}>
          Паролата ти беше актуализирана.
        </Text>
        <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.013 }]}>
          Може да влезеш в профила си и да започнеш да играеш!
        </Text>
        <ImageButton06
          label="ВЛЕЗ"
          onPress={onPress}
          style={[styles.btnSubmit]}
          source={images["btn-big"]}
          labelStyle={{fontSize: baseWidth * 0.013}}
        />
      </View>
    </View>
  );
};

export default Success;
