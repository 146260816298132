import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    imageStyle: {
      width: "100%",
      height: "100%",
    },
    bodyStyle: {
      zIndex: 2,
      top: "9%",
      width: "60%",
      height: "70%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_referee: {
      bottom: "1%",
      width: "16.3%",
      height: "75%",
      right: "4%",
      position: "absolute",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    heightTop: { height: "20%" },
    t_signup_using: { 
      fontFamily: "Roboto-Bold",
      color: colors.white,
      textAlign: "center",
      marginBottom: "1%"
    },
    viewCenter: {
      height: "75%",
      width: "100%",
      paddingTop: "2%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    v_social_login: {
      width: "65%",
      height: "24%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    smallLogoContainer: {
      height: "51%",
      width: "80%",
      top: "-29%",
      position: "absolute",
    },
    viewTitle: {
      height: "12%",
      width: "50%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageTitle: {
      width: "86%",
      height: "100%",
    },
    separator: {
      height: 25,
      width: "40%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    divider: {
      height: 1,
      width: "30%",
      backgroundColor: "white",
    },
    txtOrstyle: {
      color: "white",
      paddingHorizontal: 10,
      fontFamily: "Roboto",
    },
    btn_reg_email: {
      width: "45%",
      height: "100%",
    },
    buttonStyle: {
      width: "49%",
      height: "100%",
    },
    t_login: { fontFamily: "Roboto", fontSize: 12, color: colors.black },
    t_version: {
      bottom: "3%",
      fontSize: 10,
      color: "white",
      position: "absolute",
      fontFamily: "Roboto",
    },

    t_terms_condition: { 
      top: "10%",
      width: "60%",
      fontFamily: "Roboto-Medium",
      color: colors.white,
      textAlign: "center",
    },
    t_terms_condition_active: { 
      textDecorationLine: "underline",
      color: colors.yellow9,
    },

    // ALREADY SIGNED COMPONENT STYLE
    viewLogin: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    textWrapper: {
      flexDirection: "row",
      marginBottom: 15,
    },
    txtLogin: {
      fontSize: 15,
      color: colors.white,
      fontFamily: "Roboto",
    },
    hyperLink: {
      color: colors.white,
      textDecorationLine: "underline",
    },
    txtVersion: {
      fontSize: 13,
      color: colors.white,
      fontFamily: "Roboto-Light",
    },
    bottomHeight: {
      width: 100,
      height: "3%",
    },

    // MAINTENANCE COMPONENT STYLE
    maintenanceStyle: {
      flex: 1,
      paddingRight: 20,
      paddingBottom: 25,
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    btnExit: {
      zIndex: 1,
      width: "23%",
      height: "25%",
    },
  },
);

export default useStyles;
