import React from "react";
import { useNavigation } from "@react-navigation/native";
import { ImageButton02 } from "components/button";
import { Image, Linking } from "react-native";
import useStyles from "./styles.css";
import { useLobbyService } from "@src/ducks/hooks";

const ActionItem = ({ name, icon }: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onChangeScreen } = useLobbyService();
  
  const onPress = () => {
    switch (name) {
    case "Home":
      onChangeScreen({ screen: "main" });
      navigation.navigate("Home");
      break;
    case "Settings":
      navigation.navigate("Settings");
      break;
    case "Shop":
      navigation.navigate("BuyShop");
      break;
    case "Share":
      Linking.openURL("https://www.facebook.com/Fortune8GWz");
      break;
    }
  };
  
  return (
    <ImageButton02
      onPress={onPress}
      style={[styles.v_item_container, name === "Share" && styles.v_share]} >
      <Image style={styles.imageStyle} source={icon} resizeMode="stretch" />
    </ImageButton02>
  );
};

export default ActionItem;
