import images from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedPalmDetails } from "@src/ducks/slices/auth.slice";
import _ from "lodash";
import React from "react";
import { Image, View } from "react-native";
import LoginToPalms from "../login/login-to-palms";
import SignOption from "./sign-options";
import useStyles from "./styles.css";

const SignInOption = () => {
  const styles = useStyles();
  const palmsBetDetails = useAppSelector(selectedPalmDetails);

  return (
    <View style={styles.container}>
       <Image
        source={{uri: images["bg-splash"]}}
        style={[styles.i_background]}
        resizeMode="stretch"
      />
      {!_.isEmpty(palmsBetDetails) ? <LoginToPalms /> : <SignOption />}
    </View>
  );
};

export default SignInOption;
