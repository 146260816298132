import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useRoute } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useAuthService, useGModeService } from "@src/ducks/hooks";
import { selectedGoldGamesEnable } from "@src/ducks/slices/lobby.slice";
import { selectedUserEmail } from "@src/ducks/slices/user.slice";
import React, { useEffect } from "react";
import { View } from "react-native";
import GameView from "./GameView";
import Drawer from "./actionbutton";
import CoinsDialog from "./coins-dialog";
import GModeProvider from "./provider";
import useStyles from "./styles.css";
import useSoundService from "@src/ducks/hooks/useSoundService";

const GameMode = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const sendDataToGTM = useGTMDispatch();
  const gameTypeID = route?.params?.gameTypeID;
  const emailAddress = useAppSelector(selectedUserEmail);
  const { bgsoundPause, bgsoundPlay } = useSoundService();
  const { onGModeRequest, onLeaveGModeRequest } = useGModeService();
  const isGoldGamesEnable = useAppSelector(selectedGoldGamesEnable);

  useEffect(() => {
    bgsoundPause();
    onGModeRequest({
      gamecode: route.params.code,
      gameTypeID: route.params.gameTypeID,
      packageFilePath: route.params.packageFilePath,
      currency: isGoldGamesEnable ? "GOLD" : "SILVER",
    });
    sendDataToGTM({
      event: "game_event",
      game_name: route.params.gameName,
      email: emailAddress,
      game_provider: gameTypeID === 5 ? "Lion Dragon Games" : "Evo Games",
      dataLayer: {
        game_name: route.params.gameName,
        email: emailAddress,
        game_provider: gameTypeID === 5 ? "Lion Dragon Games" : "Evo Games",
      },
    });
    return () => {
      onLeaveGModeRequest(route.params.gameCategoryID);
      bgsoundPlay();
    };
  }, []);

  return (
    <GModeProvider>
      <View style={styles.container}>
        <GameView />
        <Drawer />
        <CoinsDialog />
      </View>
    </GModeProvider>
  );
};

export default React.memo(GameMode);
