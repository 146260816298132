import { CreateResponsiveStyle } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "58%",
      height: "72.53%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    smallLogoContainer: {
      height: "48%",
      width: "80%",
      top: "-25%",
      position: "absolute",
    },
    bottomHeight: {
      width: 100,
      height: "5%",
    },
    header: {
      zIndex: 1,
      fontSize: 30,
      paddingTop: 48,
      color: "#6C0B7B",
      marginBottom: 40,
      fontFamily: "Roboto-Medium",
    },
    forgotWrapper: { 
      width: "60%", 
      marginTop: "0.6%",
      flexDirection: "row", 
      justifyContent: "space-between" },
    btnForgot: { alignSelf: "flex-end" },
    txtSignUp: {
      fontSize: 14.8,
      color: colors.white,
      fontWeight: "500",
      fontFamily: "Roboto-Medium",
    },
    txtSignUp_bold: {
      fontFamily: "Roboto-Bold",
    },
    txtForgot: {
      fontSize: 14,
      color: colors.white,
      fontFamily: "Roboto",
      textDecorationLine: "underline",
    },
    v_inputs: {
      width: "100%",
      height: "44%",
      alignItems: "center",
      justifyContent: "center",
    },
    viewLoginStyle: {
      width: "49%",
      height: "15%",
      marginTop: "2%",
      alignItems: "center",
      justifyContent: "center",
    },
    loginTextStyle: { width: "80%", height: "100%" },
    textAlign: { textAlign: "center", paddingLeft: 0 },
    ti_email: {
      height: "26%",
      marginBottom: "1.8%"
    },

    // FOOTER COMPONENT STYLE
    btnLogin: { width: "23%", height: "15%", marginTop: "1%" },

    // login to palms
    palms_container: {
      zIndex: 6,
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.translucent3,
    },
    palms_center: {
      width: "60%",
      height: "76%",
      alignItems: "center",
      justifyContent: "center",
    },
    palms_logo: {
      height: "48%",
      width: "60%",
      top: "-23%",
      position: "absolute",
    },
    palms_row: {
      marginTop: "4%",
      width: "70%",
      height: "20%",
      alignSelf: "center",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    palms_notice_icon: {
      height: "66%", width: "8%",
    },
    palms_notes: {
      marginLeft: "4%",
      fontFamily: "Roboto-Bold",
      fontSize: 15,
      color: colors.white
    },
    palms_btn_continue: { height: "21%", width: "24%", marginTop: "3%" },

  },
);

export default useStyles;
