import { SagaIterator } from "@redux-saga/core";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

// Types
import * as Types from "../types";

// API
import {
  fetchGameURLCurrency,
  fetchGameURLSwordPlay,
  fetchMissions,
  jadeEggAccumulation,
  swordplayPaymentCallback,
  swordplayPaymentInit,
  walletBalance
} from "@src/utils/api";

// Slice
import _ from "lodash";
import moment from "moment";
import { compareMissionsBeforeAndAfter } from "utils/transform-helper";
import { authActions, selectedAuthToken } from "../slices/auth.slice";
import { gmodeActions } from "../slices/gmode.slice";
import { lobbyActions, selectedMissions } from "../slices/lobby.slice";
import { selectedUserUserID, userActions } from "../slices/user.slice";

function* handleGameModeRequest(action: {
  type: typeof gmodeActions.gmodeRequest;
  payload: Types.GameMode;
}): SagaIterator {
  try {
    const gameTypeID = action.payload.gameTypeID;
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);

    let result = null;

    if (gameTypeID === 7) {
      const timestamp = +moment();
      result = yield call(fetchGameURLSwordPlay, {...action.payload, userId, timestamp}, accessToken);
    }else{
      result = yield call(fetchGameURLCurrency, {...action.payload, userId }, accessToken);
    }

    if (!_.isEmpty(result.error)) {
      throw {
        code: 401,
        message: "Unable to load game. Please come back later",
      };
    }
    
    let link = result?.data?.link ?? "";
    link = link.replace("http://play", "https://play");

    yield put(gmodeActions.gmodeSuccess(link));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
      yield put(authActions.logout());
    }
    yield put(gmodeActions.gmodeFailure(error?.message ?? error));
  }
}

function* handleMissionsRequest(): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);

    const missions = yield select(selectedMissions);
    const completedMission = yield call(fetchMissions, { userId }, accessToken);
    const endAt = completedMission.data.endAt;
    const status = completedMission.data.status;
    const userMissions = yield call(compareMissionsBeforeAndAfter, missions, completedMission.data.userMissions);

    yield put(lobbyActions.missions({endAt, status, userMissions}));
  } catch (error: any) {
    console.log("error.statuserror.status", error);
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
    }
  }
}

function* handleSwordplayRequest(action: {
  type: typeof gmodeActions.topupSwordPlayRequest;
  payload: Types.SwordPlayInit & Types.Token;
}): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);

    const payment_init_params = {
      currency: action.payload.currency || "SILVER",
      cpOrderId: action.payload.cpOrderId,
      extParams: action.payload.extParams,
      productDesc: action.payload.productDesc,
      productId: action.payload.productId,
      productName: action.payload.productName,
      productNumber: action.payload.productNumber,
      productPrice: action.payload.productPrice || 0,
      serverId: action.payload.serverId,
      userId: userId,
    };

    if(action.payload.currency === "SILVER"){
      const price = Math.round(action.payload.productPrice || 0);
      payment_init_params.productPrice = price * 50000;
    }

    const payment_callback_params = {
      account: userId,
      coin: Math.round(action.payload.productPrice || 0),
      cpOrderId: action.payload.cpOrderId,
      money: action.payload.productPrice,
      productId: action.payload.productId,
      serverId: action.payload.serverId,
    };

    yield call(swordplayPaymentInit, payment_init_params, accessToken);
    const paymentcallback = yield call(swordplayPaymentCallback, payment_callback_params, accessToken);

    yield put(gmodeActions.topupSwordPlaySuccess(paymentcallback));
    yield delay(1000);
    yield put(gmodeActions.topupSwordPlaySuccess({}));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
    }
    yield put(gmodeActions.topupSwordPlayFailure(error?.error ?? error));
  }
}

function* handleGameModeLeave(): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);

    const balance = yield call(walletBalance, {userId}, accessToken);
    yield put(userActions.updateWallet(balance.data));

    const jadeEgg = yield call(jadeEggAccumulation, { userId }, accessToken);
    yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));

  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Сесията е изтекла"));
    }
  }
}

// Watcher Saga
function* gmodeWatcherSaga(): SagaIterator {
  yield takeEvery(gmodeActions.gmodeRequest.type, handleGameModeRequest);
  yield takeEvery(gmodeActions.missionRequest.type, handleMissionsRequest);
  yield takeEvery(gmodeActions.topupSwordPlayRequest.type, handleSwordplayRequest);
  yield takeEvery(gmodeActions.gameModeLeave.type, handleGameModeLeave);
}

export default gmodeWatcherSaga;
