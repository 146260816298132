import images from "@assets/images";
import SVGSettings from "@assets/svg/settings";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectSoundEnabled } from "@src/ducks/slices/sound.slice";
import React from "react";
import { Image, View } from "react-native";
import Switch from "./Switch";
import useStyles from "./styles.css";

export default ({baseWidth, baseHeight}: any) => {
  const styles = useStyles();
  const bgSound = useAppSelector(selectSoundEnabled);

  return (
    <View style={styles.v_fbandsound}>
      <Image style={{width: "40%", height: "40%"}}
        source={{uri: images["text-sound"]}}
        resizeMode="stretch" />
      <View style={[styles.v_sound, { width: baseWidth * 0.3, height: baseHeight * 0.086}]}>
        <View style={{position: "absolute"}}>
          <SVGSettings name="bg-sound" width={baseWidth * 0.3} height={baseHeight * 0.086} isActive={bgSound} />
        </View>
        <Switch size={baseHeight * 0.061} />
      </View>
    </View>
  );
};
