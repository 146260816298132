import images, { SplashAssets } from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { Loading02 } from "components/loading";
import Constants from "expo-constants";
import React from "react";
import { Image, Text, View } from "react-native";
import useCacheImage from "utils/cache-helper";
import ProgressBar from "./progress-bar";
import useStyles from "./styles.css";

const VERSION = `Версия ${Constants.expoConfig?.version}`;

type Props = {
  onComplete: () => void;
}

const Splash = ({ onComplete }: Props) => {
  const styles = useStyles();
  const appIsReady = useCacheImage(SplashAssets);
  const { maxHeight } = React.useContext(DimensionContext);

  if(!appIsReady){
    return <Loading02 />;
  }

  return (
    <View style={styles.container}>
      <Image style={styles.i_background} source={{uri: images["bg-splash"]}} resizeMode="stretch" />
      <Image
        source={{uri: images["palm-tree"]}}
        style={styles.i_logo}
        resizeMode="stretch"
      />
      <Image
        source={{uri: images["gamezone-logo-horizontal"]}}
        style={styles.i_logo_horizontal}
        resizeMode="stretch"
      />
      <Image
        source={{uri: images["elements"]}}
        style={styles.i_elements}
        resizeMode="stretch"
      />
      <Image
        source={{uri: images["referee"]}}
        style={styles.i_referee}
        resizeMode="stretch"
      />
      <ProgressBar maxHeight={maxHeight} onComplete={onComplete} />
      <Text style={[styles.t_version, {fontSize: maxHeight * 0.014}]}>{VERSION}</Text>
    </View>
  );
};

export default Splash;
