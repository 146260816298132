import { CDN_URL } from "@env";
import { CongratsTypes } from "./image-types/congrats-types";

const MissionVaultAssets: CongratsTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/mission-vault/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/mission-vault/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/mission-vault/images/img_2_bgn.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/mission-vault/images/img_3_bgn.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/mission-vault/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/mission-vault/images/img_5.png",
};

export default MissionVaultAssets;
