import images from "@assets/images";
import { colors } from "@src/theme";
import { ImageButton02 } from "components/button";
import React, { useCallback } from "react";
import { Image, StyleSheet } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import useMetrics from "utils/metrics";
import Actions from "./Actions";
import useConfig from "./useConfig";

function ActionButton() {
  const { SIZE, ITEM_SIZE } = useConfig();
  const actionHeight = useSharedValue(0);
  const { verticalScale } = useMetrics();

  const onAction = useCallback(() => {
    const value = actionHeight.value === 0 ? (ITEM_SIZE + 11) * 3 : 0;
    actionHeight.value = withTiming(value);
  }, [actionHeight.value, ITEM_SIZE, SIZE]);

  const HStyle = useAnimatedStyle(() => {
    return {
      height: actionHeight.value,
    };
  }, []);

  return (
    <>
      <Animated.View
        style={[
          styles.v_circle,
          {
            width: SIZE,
            minHeight: SIZE,
            borderRadius: SIZE / 2,
            top: verticalScale(30)
          },
        ]}
      >
        <ImageButton02
          onPress={onAction}
          style={[
            styles.btn_float,
            {
              width: SIZE - 10,
              height: SIZE - 10,
              borderRadius: SIZE - 10 / 2,
            },
          ]}
        >
          <Image
            style={{ width: SIZE - 30, height: SIZE - 30 }}
            source={{uri: images["gamezone-logo"]}}
            resizeMode="cover"
          />
        </ImageButton02>
        <Animated.View style={[ HStyle]}>
          <Actions actionHeight={actionHeight.value} />
        </Animated.View>
      </Animated.View>
    </>
  );
}

export default ActionButton;

const styles = StyleSheet.create({
  v_circle: {
    top: "12%",
    left: 5,
    zIndex: 1,
    borderWidth: 5,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    borderColor: colors.yellow,
    backgroundColor: colors.skyblue3,
  },
  btn_float: {
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 40 / 2,
  },
});
