import { useState, useCallback } from "react";
import { authActions, selectedAuthToken } from "@src/ducks/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { callPost } from "../request";
import moment from "moment";

type FetchPostValue = {
  loading: boolean;
  data: any;
  error: any;
  runRequest: (param1: any, param2: any) => void;
  runReset: () => void;
}

export const useFetchApcopay = (initialData = null) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState(initialData);
  const token: any = useAppSelector(selectedAuthToken);
  const [loading, setLoading] = useState<boolean>(false);

  const runRequest = useCallback(
    async (params: any, transaction: any) => {
      try {
        setLoading(true);
        const result = await callPost("/api/v1/apcopay/get_checkout_url", params);

        transaction.transactionDate = moment().format("YYYY-MM-DD HH:mm:ss");
        transaction.transactionNo = params.oref;
  
        await callPost("/api/v1/payment/transaction", transaction, token);

        setData(result.data);
      } catch (err: any) {
        if(err?.status === 401 && err?.error === "Unauthorized"){
          dispatch(authActions.logout());
          dispatch(authActions.setErrorMessage("Сесията е изтекла"));
        }
        const message = err?.error?.message ?? "Something went wrong";
        setError({ message });
      } finally {
        setLoading(false);
      }
    },
    [token, dispatch]
  );

  const runReset = useCallback(() => {
    setLoading(false);
    setData(null);
    setError(null);
  }, []);

  return {
    loading,
    data,
    error,
    runRequest,
    runReset,
  } as FetchPostValue;
};
