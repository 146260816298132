import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const Disclaimer = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={styles.container}>
      <View style={[styles.bodyStyle, 
        { width: baseWidth * 0.73, height: baseWidth * 0.4142 },
      ]}>
        <Image
          source={{uri: images.bg_transparent}}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <View style={styles.v_center}>
          <View style={[styles.viewForgotStyle]}>
            <Text style={[styles.t_title, 
              { fontSize: baseWidth * 0.035}]}>ВАЖНО 
            </Text>
          </View>
          <View style={styles.viewEmail}>
            <Text style={[styles.t_description, { fontSize: baseWidth * 0.018}]}>
            Добре дошъл в GAME ZONE. Време е да се насладиш на нашата гама от нови онлайн социални игри. Трябва да си навършил 18 години и да си наясно със следното.{"\n\n"}
            Игрите, които предлагаме в Game Zone, не са  хазартни и по никакъв начин не предлагат печалби с истински пари, реални награди или еквивалент. Game Zone е приложение само за забавни игри и нашата игрова механика не гарантира успех в какъвто и да е хазарт в бъдеще. 
            </Text>
          </View>
          <View style={styles.v_button}>
            <ImageButton06
              onPress={() => navigation.goBack()}
              style={[
                styles.btnSubmit,
              ]}
              label="ЗАТВОРИ"
              source={images["btn-big"]}
              labelStyle={{fontSize: baseWidth * 0.013}}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default Disclaimer;
