import { useRoute } from "@react-navigation/native";
import Loading from "components/loading/Loading";
import React from "react";
const DailyBonus = React.lazy(() => import("screens/daily-bonus"));
const WhistleWarrior = React.lazy(() => import("./whistle-warrior"));
const HighRoller = React.lazy(() => import("./high-roller"));
const HotDeals = React.lazy(() => import("./hot-deals"));
const TGIF = React.lazy(() => import("./tgif"));
const StarterPack = React.lazy(() => import("./starter-pack"));
const WeeklyBooster = React.lazy(() => import("./weekly-booster"));
const WelcomeBonus = React.lazy(() => import("screens/welcomebonus"));
const BuyShop = React.lazy(() => import("@src/screens/buyshop"));
const MissionKey = React.lazy(() => import("screens/mission/popupkey"));
const Inbox = React.lazy(() => import("screens/inbox"));
const HotDealsThumbnails = React.lazy(() => import("screens/popups/thumbnails"));
const LeaderBoard = React.lazy(() => import("screens/leaderboard"));
const MissionRewards = React.lazy(() => import("screens/mission/missions/mission-rewards"));
const GoldenBall = React.lazy(() => import("@src/screens/golden-ball"));
const PremiumPackage = React.lazy(() => import("@src/screens/popups/premium-package"));
const AccessDenied = React.lazy(() => import("@src/screens/popups/pop-notice/access-denied"));
const TierLevelUp = React.lazy(() => import("@src/screens/popups/tier-level-up"));
const LevelUpCongrats = React.lazy(() => import("@src/screens/popups/level-up-congrats"));
const LoginToPalms = React.lazy(() => import("@src/screens/auth/login/login-to-palms"));
const GoldConversion = React.lazy(() => import("@src/screens/gold-conversion"));
const PalmsGoldInfo = React.lazy(() => import("@src/screens/popups/pop-notice/palms-gold-info"));
const BonusGoldInfo = React.lazy(() => import("@src/screens/popups/pop-notice/bonus-gold-info"));
const InsufficientPalmsGold = React.lazy(() => import("@src/screens/popups/pop-notice/insufficient-palmgold"));
const InsufficientPalmsPoint = React.lazy(() => import("@src/screens/popups/pop-notice/insufficient-palmpoints"));

const AnimationPopup = () => {
  const route = useRoute<any>();

  const components = React.useMemo(() => {
    switch (route.name) {
    case "DailyBonus":
      return <DailyBonus />;
    case "WhistleWarrior":
      return <WhistleWarrior />;
    case "HighRoller":
      return <HighRoller />;
    case "HotDeals":
      return <HotDeals />;
    case "TGIF":
      return <TGIF />;
    case "StarterPack":
      return <StarterPack />;
    case "WeeklyBooster":
      return <WeeklyBooster />;
    case "WelcomeBonus":
      return <WelcomeBonus />;
    case "BuyShop":
      return <BuyShop />;
    case "MissionKey":
      return <MissionKey />;
    case "Inbox":
      return <Inbox />;
    case "HotDealsThumbnails":
      return <HotDealsThumbnails />;
    case "LeaderBoard":
      return <LeaderBoard />;
    case "MissionRewards":
      return <MissionRewards />;
    case "GoldenBall":
      return <GoldenBall />;
    case "PremiumPackage":
      return <PremiumPackage />;
    case "AccessDenied":
      return <AccessDenied />;
    case "TierLevelUp":
      return <TierLevelUp />;
    case "LevelUpCongrats":
      return <LevelUpCongrats />;
    case "LoginToPalms":
      return <LoginToPalms />;
    case "gold-conversion":
      return <GoldConversion />;
    case "palms-gold-info":
      return <PalmsGoldInfo />;
    case "bonus-gold-info":
      return <BonusGoldInfo />;
    case "insufficient-gold":
      return <InsufficientPalmsGold />;
    case "insufficient-palms-point":
      return <InsufficientPalmsPoint />;
    }
  }, [route.name]);

  return (
    <React.Suspense fallback={<Loading isLoading />}>
      {components}
    </React.Suspense>
  );
};

export default AnimationPopup;