import { CreateResponsiveStyle } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    v_cover: {
      zIndex: 7,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "hidden",
      position: "absolute",
    },
    container: {
      zIndex: 10,
      right: "5%",
      position: "absolute",
    },
    i_bg_settings: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    flex1: { flex: 1 },
    bg_settings: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_top: {
      height: "15%",
      width: 100,
    },
    v_bottom: {
      height: "2%",
      width: 100,
    },

    v_settings: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },

    v_fbandsound: {
      width: "80%",
      height: "10%",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    btn_fbshare: { width: "30%", height: "80%" },
    v_sound: {
      width: "50%",
      alignItems: "center",
      justifyContent: "center",
    },
    bg_sound: { left: -1, width: "100%", height: "100%", position: "absolute" },
    t_sound: {
      zIndex: 1,
      fontSize: 12,
      fontWeight: "500",
      color: colors.white,
      fontFamily: "Roboto-Medium",
    },

    v_terms: {
      width: "86%",
      marginRight: 10,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    btn_terms: { width: "50%", height: "78%" },
    btn_tier: { width: "44%", height: "78%" },

    v_policy: {
      width: "90%",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
    btn_policy: { width: "35%", height: "80%" },
    btn_disclaimer: { width: "25%", height: "80%" },

    v_logout: {
      alignItems: "center",
      justifyContent: "center",
    },
    i_logout: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    btn_logout: { width: "76%", height: "50%", right: -4 },
    t_logout: { 
      fontFamily: "Roboto-Bold", 
      color: colors.white,
      marginRight: "3%"
    },
    txt_deactivate: {
      width: "80%",
      fontSize: 12,
      marginTop: "3%",
      fontWeight: "500",
      textAlign: "center",
      textDecorationLine: "underline",
      fontFamily: "Roboto",
    },
    btn_faq: {
      bottom: 0,
      left: "6%",
      width: "20%",
      height: "20%",
      position: "absolute",
    },
    btn_about_gold: {
      bottom: 0,
      right: "8.5%",
      position: "absolute",
    },
    btn_back: {
      bottom: "-1%",
      width: "20%",
      height: "10%",
      marginRight: 5,
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },

    text_button: {
      fontFamily: "Roboto-Bold",
      color: colors.blue5,
      textDecorationLine: "underline"
    }
  },
);

export default useStyles;
