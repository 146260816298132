import { StackActions, useNavigation, useRoute } from "@react-navigation/native";
import { useAuthContext } from "@src/AuthProvider";
import { useLobbyService } from "@src/ducks/hooks";
import { selectPalmsPointBalance } from "@src/ducks/slices/user.slice";
import { colors } from "@src/theme";
import { Loading02 } from "components/loading";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useFetchPost } from "utils/api/hooks";
import { useCoinContext } from "../home/main/CoinProvider";
import PurchaseConfirmation from "../popups/pop-notice/purchase-confirmation";

const PaymentGateway = () => {
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const { onErrorMessage } = useAuthContext();
  const { onAdditionalCurrency } = useCoinContext();
  const { updatePalmsPointBalance } = useLobbyService();
  const ppbalance = useSelector(selectPalmsPointBalance);
  const { data, error, loading, runRequest, runReset } = useFetchPost();

  const onPurchase = () => {
    const productId = route?.params?.id;
    const palmsPoints = route?.params?.palmsPoints;
    if(palmsPoints && palmsPoints > ppbalance){
      navigation.navigate("insufficient-palms-point");
      return;
    }
    runRequest("/api/v1/palms/products/purchase", { productId }, "palmsbet")
  }

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      const silver = route?.params?.value;
      const goldBonus = route?.params?.goldBonus;
      navigation.dispatch(StackActions.replace("PaymentConfirm", {
        silver: silver,
        gold: goldBonus,
      }));
      onAdditionalCurrency({silver: silver, bg: goldBonus || 0, gwz: 0});
      updatePalmsPointBalance(data.palmsPointBalance);
      runReset();
    }
    if(!_.isEmpty(error?.message)){
      onErrorMessage(error?.message);
      runReset();
    }
  }, [error?.message, data]);

  if(loading || !_.isEmpty(data)){
    return (
      <Loading02 
        color="white"
        style={{
          zIndex: 8,
          paddingTop: 0,
          width: "100%",
          height: "100%",
          position: "absolute",
          alignItem: "center", 
          justifyContent: "center", 
          backgroundColor: colors.translucent
        }} />
    )
  }

  return <PurchaseConfirmation onConfirm={onPurchase} />;
};

export default PaymentGateway;
