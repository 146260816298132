import * as React from "react";
import Svg, { Rect } from "react-native-svg";

const BGSound = ({width, height, isActive}: any) => (
  <Svg 
    fill="none"
    viewBox="0 0 50 22"
    {...{width, height}}>
    <Rect width={50} height={20.139} y={1.389} fill={isActive ? "#1A8312" : "#4E4F4E"} rx={10.069} />
    <Rect width={50} height={20.139} fill={isActive ? "#27D41F": "#838283"} rx={10.069} />
  </Svg>
);

export default BGSound;
