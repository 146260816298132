import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { colors } from "@src/theme";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, ScrollView, Text, View } from "react-native";
import useStyles from "./styles.css";

const TierMoreInfo = () => {
  const styles = useStyles();
  const navigation = useNavigation();
  const { baseWidth, height, maxHeight } = React.useContext(DimensionContext);
  const WIDTH = baseWidth * 0.7;

  return (
    <View style={styles.container}>
      <View style={[styles.v_info_more, { width: WIDTH, height }]}>
        <Image
          source={{uri: images["bg_lboard"]}}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <ScrollView style={[styles.scrollview, { height }]}>
          <View style={[styles.v_content]}>
            <Image
              source={{uri: images["text-tier-explanation"]}}
              style={styles.i_tier_explanation}
              resizeMode="stretch"
            />
            <View style={{width: "70%", marginTop: "5%" }}>
              <Text style={[styles.t_regular, {color: colors.yellow5, fontFamily: "Roboto-Bold", fontSize: baseWidth * 0.016}]}>МЕДНО НИВО- НАЧАЛНО НИВО{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Достъп до ограничен брой игри- слотове, карти и лотария.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Достъп до всички бонуси и награди в приложението.{"\n\n"}</Text>

              <Text style={[styles.t_regular, {color: colors.yellow5, fontFamily: "Roboto-Bold", fontSize: baseWidth * 0.016}]}>БРОНЗОВО НИВО- ИГРАЧЪТ Е ИЗИГРАЛ PP10+ ЗА ПЕРИОД ОТ 12 МЕСЕЦА{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Получаваш 3 000 000 Бонусни Сребърни монети.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Допълнителен достъп до Премиум игри- слотове, карти, RPG, аркадни и лотарийни игри.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Достъп до всички бонуси и награди в приложението.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Възможност да получиш Palms златни монети за всяка игра.</Text>

              <Text style={[styles.t_regular, {color: colors.yellow5, fontFamily: "Roboto-Bold", fontSize: baseWidth * 0.016}]}>СРЕБЪРНО НИВО- ИГРАЧЪТ Е ИЗИГРАЛ PP30+ ЗА ПЕРИОД ОТ 12 МЕСЕЦА{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Получаваш 10 000 000 Бонус монети</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Достъп до Премиум игри- слотове, карти, RPG, аркадни и лотарийни игри. </Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Достъп до всички бонуси и награди в приложението.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Възможност да получиш Palms златни монети за всяка игра.{"\n\n"}</Text>

              <Text style={[styles.t_regular, {color: colors.yellow5, fontFamily: "Roboto-Bold", fontSize: baseWidth * 0.016}]}>ЗЛАТНО НИВО- ИГРАЧЪТ Е ИЗИГРАЛ PP50+ ЗА ПЕРИОД ОТ 12 МЕСЕЦА{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Получаваш 17 000 000 Бонус монети</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Пълен достъп до Премиум и ВИП игри- слотове, карти, RPG, аркадни и лотарийни игри, плюс ранен достъп до новите игри.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Достъп до всички бонуси и награди в приложението.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.014}]}>• Възможност да получиш Palms златни монети за всяка игра.{"\n\n"}</Text>
              
            </View>
            <View style={styles.v_notes}>
              <Image
                source={{uri: images["exclamation"]}}
                style={{width: baseWidth * 0.03, height: baseWidth * 0.03}}
                resizeMode="stretch"
              />
              <Text style={[styles.t_description, {textAlign: "left", fontSize: baseWidth * 0.0125 }]}>ВАЖНО: Профили, които не са били активни 12 месеца или повече, автоматично ще бъдат върнати до Медно ниво. </Text>
            </View>
          
            <ImageButton06
              label="ЗАТВОРИ"
              onPress={() => navigation.goBack()}
              style={[styles.btnSubmit, { marginTop: "5%", marginBottom: "4%", height: maxHeight * 0.07 }]}
              width={baseWidth * 0.12}
              height={baseWidth * 0.05}
              source={images["btn-big"]}
              labelStyle={{fontSize: baseWidth * 0.011}}
            />
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default TierMoreInfo;
