import { CDN_URL } from "@env";
import { MissionRewardTypes } from "./image-types/mission-reward-types";

const MissionRewardAssets: MissionRewardTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_1_bgn.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_2_bgn.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_7.png",
  "img_8": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_8.png",
  "img_9": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_9.png",
  "img_10": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_10.png",
  "img_11": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_11.png",
  "img_12": CDN_URL + "/image-assets/animation-assets/mission-rewards/images/img_12.png",
};

export default MissionRewardAssets;
