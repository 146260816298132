import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      backgroundColor: colors.translucent,
    },
    scrollview: {
      width: "100%",
      height: "100%",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },

    // TIERINFOMORE
    btnSubmit: {
      width: "17%",
      left: "40%",
    },
    v_scroll: {
      top: "10%",
      right: "5%",
      zIndex: 6,
      position: "absolute",
    },
    v_content: {
      width: "100%",
      height: "92%",
      alignItems: "center",
    },
    t_title: {
      marginTop: "6%",
      textDecorationLine: "underline",
      fontFamily: "Roboto-Bold",
      textAlign: "center", 
      color: colors.yellow5,
    },
    t_regular: {
      fontFamily: "Roboto", 
      color: colors.white
    },
    v_info_more: {
      left: "15%",
      width: "80%",
      height: "180%",
    },
    btn_close_more: {
      width: 50,
      height: 50,
      top: "3%",
      right: "6%",
      zIndex: 6,
      position: "absolute",
    },
  },
);

export default useStyles;
