import images from "@assets/images";
import { ImageButton } from "components/button";
import React from "react";
import { View } from "react-native";
import useStyles from "./styles.css";

type Props = {
  onPress: () => void;
}

const ItemShop = ({onPress}: Props) => {
  const styles = useStyles();

  return (
    <View style={styles.v_container_shop}>
      <ImageButton 
        scaleSize={1}
        onPress={onPress} 
        style={styles.v_item_shop}
        source={{uri: images["btn-convert"]}}
      />
    </View>
  );
};

export default ItemShop;
