import { CDN_URL } from "@env";
import { HotDealsTypes } from "./image-types/hot-deals-types";

const HotDealsAssets: HotDealsTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/hot-deals/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/hot-deals/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/hot-deals/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/hot-deals/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/hot-deals/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/hot-deals/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/hot-deals/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/hot-deals/images/img_7.png",
  "seq_0_0": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_0.png",
  "seq_0_1": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_1.png",
  "seq_0_2": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_2.png",
  "seq_0_3": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_3.png",
  "seq_0_4": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_4.png",
  "seq_0_5": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_5.png",
  "seq_0_6": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_6.png",
  "seq_0_7": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_7.png",
  "seq_0_8": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_8.png",
  "seq_0_9": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_9.png",
  "seq_0_10": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_10.png",
  "seq_0_11": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_11.png",
  "seq_0_12": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_12.png",
  "seq_0_13": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_13.png",
  "seq_0_14": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_14.png",
  "seq_0_15": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_15.png",
  "seq_0_16": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_16.png",
  "seq_0_17": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_17.png",
  "seq_0_18": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_18.png",
  "seq_0_19": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_19.png",
  "seq_0_20": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_20.png",
  "seq_0_21": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_21.png",
  "seq_0_22": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_22.png",
  "seq_0_23": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_23.png",
  "seq_0_24": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_24.png",
  "seq_0_25": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_25.png",
  "seq_0_26": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_26.png",
  "seq_0_27": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_27.png",
  "seq_0_28": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_28.png",
  "seq_0_29": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_29.png",
  "seq_0_30": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_30.png",
  "seq_0_31": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_31.png",
  "seq_0_32": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_32.png",
  "seq_0_33": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_33.png",
  "seq_0_34": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_34.png",
  "seq_0_35": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_35.png",
  "seq_0_36": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_36.png",
  "seq_0_37": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_37.png",
  "seq_0_38": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_38.png",
  "seq_0_39": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_39.png",
  "seq_0_40": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_40.png",
  "seq_0_41": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_41.png",
  "seq_0_42": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_42.png",
  "seq_0_43": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_43.png",
  "seq_0_44": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_44.png",
  "seq_0_45": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_45.png",
  "seq_0_46": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_46.png",
  "seq_0_47": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_47.png",
  "seq_0_48": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_48.png",
  "seq_0_49": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_49.png",
  "seq_0_50": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_50.png",
  "seq_0_51": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_51.png",
  "seq_0_52": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_52.png",
  "seq_0_53": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_53.png",
  "seq_0_54": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_54.png",
  "seq_0_55": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_55.png",
  "seq_0_56": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_56.png",
  "seq_0_57": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_57.png",
  "seq_0_58": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_58.png",
  "seq_0_59": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_59.png",
  "seq_0_60": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_60.png",
  "seq_0_61": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_61.png",
  "seq_0_62": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_62.png",
  "seq_0_63": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_63.png",
  "seq_0_64": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_64.png",
  "seq_0_65": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_65.png",
  "seq_0_66": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_66.png",
  "seq_0_67": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_67.png",
  "seq_0_68": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_68.png",
  "seq_0_69": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_69.png",
  "seq_0_70": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_70.png",
  "seq_0_71": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_71.png",
  "seq_0_72": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_72.png",
  "seq_0_73": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_73.png",
  "seq_0_74": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_74.png",
  "seq_0_75": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_75.png",
  "seq_0_76": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_76.png",
  "seq_0_77": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_77.png",
  "seq_0_78": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_78.png",
  "seq_0_79": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_79.png",
  "seq_0_80": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_80.png",
  "seq_0_81": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_81.png",
  "seq_0_82": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_82.png",
  "seq_0_83": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_83.png",
  "seq_0_84": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_84.png",
  "seq_0_85": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_85.png",
  "seq_0_86": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_86.png",
  "seq_0_87": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_87.png",
  "seq_0_88": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_88.png",
  "seq_0_89": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_89.png",
  "seq_0_90": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_90.png",
  "seq_0_91": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_91.png",
  "seq_0_92": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_92.png",
  "seq_0_93": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_93.png",
  "seq_0_94": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_94.png",
  "seq_0_95": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_95.png",
  "seq_0_96": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_96.png",
  "seq_0_97": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_97.png",
  "seq_0_98": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_98.png",
  "seq_0_99": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_99.png",
  "seq_0_100": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_100.png",
  "seq_0_101": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_101.png",
  "seq_0_102": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_102.png",
  "seq_0_103": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_103.png",
  "seq_0_104": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_104.png",
  "seq_0_105": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_105.png",
  "seq_0_106": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_106.png",
  "seq_0_107": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_107.png",
  "seq_0_108": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_108.png",
  "seq_0_109": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_109.png",
  "seq_0_110": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_110.png",
  "seq_0_111": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_111.png",
  "seq_0_112": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_112.png",
  "seq_0_113": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_113.png",
  "seq_0_114": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_114.png",
  "seq_0_115": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_115.png",
  "seq_0_116": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_116.png",
  "seq_0_117": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_117.png",
  "seq_0_118": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_118.png",
  "seq_0_119": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_119.png",
  "seq_0_120": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_120.png",
  "seq_0_121": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_121.png",
  "seq_0_122": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_122.png",
  "seq_0_123": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_123.png",
  "seq_0_124": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_124.png",
  "seq_0_125": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_125.png",
  "seq_0_126": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_126.png",
  "seq_0_127": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_127.png",
  "seq_0_128": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_128.png",
  "seq_0_129": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_129.png",
  "seq_0_130": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_130.png",
  "seq_0_131": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_131.png",
  "seq_0_132": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_132.png",
  "seq_0_133": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_133.png",
  "seq_0_134": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_134.png",
  "seq_0_135": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_135.png",
  "seq_0_136": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_136.png",
  "seq_0_137": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_137.png",
  "seq_0_138": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_138.png",
  "seq_0_139": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_139.png",
  "seq_0_140": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_140.png",
  "seq_0_141": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_141.png",
  "seq_0_142": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_142.png",
  "seq_0_143": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_143.png",
  "seq_0_144": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_144.png",
  "seq_0_145": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_145.png",
  "seq_0_146": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_146.png",
  "seq_0_147": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_147.png",
  "seq_0_148": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_148.png",
  "seq_0_149": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_149.png",
  "seq_0_150": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_150.png",
  "seq_0_151": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_151.png",
  "seq_0_152": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_152.png",
  "seq_0_153": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_153.png",
  "seq_0_154": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_154.png",
  "seq_0_155": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_155.png",
  "seq_0_156": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_156.png",
  "seq_0_157": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_157.png",
  "seq_0_158": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_158.png",
  "seq_0_159": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_159.png",
  "seq_0_160": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_160.png",
  "seq_0_161": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_161.png",
  "seq_0_162": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_162.png",
  "seq_0_163": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_163.png",
  "seq_0_164": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_164.png",
  "seq_0_165": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_165.png",
  "seq_0_166": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_166.png",
  "seq_0_167": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_167.png",
  "seq_0_168": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_168.png",
  "seq_0_169": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_169.png",
  "seq_0_170": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_170.png",
  "seq_0_171": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_171.png",
  "seq_0_172": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_172.png",
  "seq_0_173": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_173.png",
  "seq_0_174": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_174.png",
  "seq_0_175": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_175.png",
  "seq_0_176": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_176.png",
  "seq_0_177": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_177.png",
  "seq_0_178": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_178.png",
  "seq_0_179": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_179.png",
  "seq_0_180": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_180.png",
  "seq_0_181": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_181.png",
  "seq_0_182": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_182.png",
  "seq_0_183": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_183.png",
  "seq_0_184": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_184.png",
  "seq_0_185": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_185.png",
  "seq_0_186": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_186.png",
  "seq_0_187": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_187.png",
  "seq_0_188": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_188.png",
  "seq_0_189": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_189.png",
  "seq_0_190": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_190.png",
  "seq_0_191": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_191.png",
  "seq_0_192": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_192.png",
  "seq_0_193": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_193.png",
  "seq_0_194": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_194.png",
  "seq_0_195": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_195.png",
  "seq_0_196": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_196.png",
  "seq_0_197": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_197.png",
  "seq_0_198": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_198.png",
  "seq_0_199": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_0_199.png",

  "seq_1_0": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_0.png",
  "seq_1_1": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_1.png",
  "seq_1_2": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_2.png",
  "seq_1_3": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_3.png",
  "seq_1_4": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_4.png",
  "seq_1_5": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_5.png",
  "seq_1_6": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_6.png",
  "seq_1_7": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_7.png",
  "seq_1_8": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_8.png",
  "seq_1_9": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_9.png",
  "seq_1_10": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_10.png",
  "seq_1_11": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_11.png",
  "seq_1_12": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_12.png",
  "seq_1_13": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_13.png",
  "seq_1_14": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_14.png",
  "seq_1_15": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_15.png",
  "seq_1_16": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_16.png",
  "seq_1_17": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_17.png",
  "seq_1_18": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_18.png",
  "seq_1_19": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_19.png",
  "seq_1_20": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_20.png",
  "seq_1_21": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_21.png",
  "seq_1_22": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_22.png",
  "seq_1_23": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_23.png",
  "seq_1_24": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_24.png",
  "seq_1_25": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_25.png",
  "seq_1_26": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_26.png",
  "seq_1_27": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_27.png",
  "seq_1_28": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_28.png",
  "seq_1_29": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_29.png",
  "seq_1_30": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_30.png",
  "seq_1_31": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_31.png",
  "seq_1_32": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_32.png",
  "seq_1_33": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_33.png",
  "seq_1_34": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_34.png",
  "seq_1_35": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_35.png",
  "seq_1_36": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_36.png",
  "seq_1_37": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_37.png",
  "seq_1_38": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_38.png",
  "seq_1_39": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_39.png",
  "seq_1_40": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_40.png",
  "seq_1_41": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_41.png",
  "seq_1_42": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_42.png",
  "seq_1_43": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_43.png",
  "seq_1_44": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_44.png",
  "seq_1_45": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_45.png",
  "seq_1_46": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_46.png",
  "seq_1_47": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_47.png",
  "seq_1_48": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_48.png",
  "seq_1_49": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_49.png",
  "seq_1_50": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_50.png",
  "seq_1_51": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_51.png",
  "seq_1_52": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_52.png",
  "seq_1_53": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_53.png",
  "seq_1_54": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_54.png",
  "seq_1_55": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_55.png",
  "seq_1_56": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_56.png",
  "seq_1_57": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_57.png",
  "seq_1_58": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_58.png",
  "seq_1_59": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_59.png",
  "seq_1_60": CDN_URL + "/image-assets/animation-assets/hot-deals/images/seq_1_60.png",
};

export default HotDealsAssets;
