import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const TierLevelReward = ({ onPress }: any) => {
  const styles = useStyles();
  const { baseWidth, maxHeight } = React.useContext(DimensionContext);

  return (
    <View style={styles.container2}>
      <View style={[styles.bodyStyle,
        { width: baseWidth * 0.73, height: baseWidth * 0.4142 },
      ]}>
        <Image
          source={{uri: images.bg_popup}}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <View style={styles.v_center}>
          <View style={[styles.viewForgotStyle]}>
            <Image
              source={{uri: images["text-tier-level-rewards"]}}
              style={styles.i_tier_level_rewards}
              resizeMode="stretch"
            />
          </View>
          <View style={styles.viewEmail}>
            <Text style={[styles.t_description, {textAlign: "left", fontSize: baseWidth * 0.016}]}>
            Надяваме се, че се наслаждаваш максимално, докато играеш на нашите игри, и тъй като ние обичаме да даваме на нашите потребители много бонуси и награди, сме осигурили награди за ниво за всички.{"\n\n"}
            Има голям избор от игри, на които може да играеш безплатно. Като платформа за забавни игри, тези игри няма да ти донесат истински пари или награди.{"\n\n"}
            Наградите за ниво са нашият начин да ти върнем вложеното от теб. Тази функция е активна за всички профили и автоматично ще преминеш на по- горно ниво, в зависимост от активността на твоя профил. 
            </Text>
          </View>
          <View style={styles.v_button}>
            <ImageButton06
              onPress={onPress}
              style={[styles.btnSubmit, { height: maxHeight * 0.07}]}
              label="Прочетете още"
              source={images["btn-big"]}
              labelStyle={{fontSize: baseWidth * 0.012}}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default TierLevelReward;
