import { NavigationContainer } from "@react-navigation/native";
import Loading from "@src/components/loading/Loading";
import React from "react";
import AppRouting from "./AppRouting";
import Linking from "./Linking";

const AppNavigator = () => {
  const linking = Linking();
  
  return (
    <NavigationContainer linking={linking} fallback={<Loading />}>
      <AppRouting />
    </NavigationContainer>
  );
};

export default AppNavigator;
