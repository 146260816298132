import images from "@assets/images";
import React, { useEffect } from "react";
import { Image, Text, View } from "react-native";
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from "react-native-reanimated";
import usePreloadingHelper from "utils/preloading-helper";
import useStyles from "./styles.css";

type Props = {
  maxHeight: number;
  onComplete: () => void;
}

const ProgressBar = ({ maxHeight, onComplete}: Props) => {
  const styles = useStyles();
  const animated = useSharedValue(0);
  const progress = usePreloadingHelper();

  useEffect(() => {
    animated.value = withTiming(progress, { duration: 100 },(finished) => {
      if (finished && progress >= 100) runOnJS(onComplete)();
    });
  }, [progress]);

  const animateStyle = useAnimatedStyle(() => {
    return {
      width: `${animated.value}%`
    };
  }, []);

  return (
    <>
      <Text style={[styles.t_loading, {fontSize: maxHeight * 0.02}]}>
      Зареждане... Няма да отнеме много време, моля, изчакай.
      </Text>
      <View style={styles.v_progress}>
        <Image
          source={{uri: images["bg-progress"]}}
          style={styles.i_progress_bg}
          resizeMode="stretch"
        />
        <View style={styles.v_maskprogress}>
          <Animated.View style={[styles.progress_container, animateStyle]} />
        </View>
      </View>
    </>
  );
};

export default ProgressBar;
