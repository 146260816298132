import images from "@assets/images";
import {
  ANDROID_CLIENT_ID,
  EXPO_CLIENT_ID,
  IOS_CLIENT_ID,
  WEB_CLIENT_ID,
} from "@env";
import { ImageButton } from "@src/components/button";
import { useAuthService } from "@src/ducks/hooks";
import * as Google from "expo-auth-session/providers/google";
import * as WebBrowser from "expo-web-browser";
import React from "react";
import useStyles from "./styles.css";

const config = {
  iosClientId: IOS_CLIENT_ID,
  webClientId: WEB_CLIENT_ID,
  expoClientId: EXPO_CLIENT_ID,
  androidClientId: ANDROID_CLIENT_ID,
};

WebBrowser.maybeCompleteAuthSession();

export default function App() {
  const styles = useStyles();
  const {onSignInWithGoogle} = useAuthService();
  const [request, response, promptAsync]: any = Google.useAuthRequest(config);

  React.useEffect(() => {
    if (response?.type === "success") {
      const { accessToken } = response.authentication;
      onSignInWithGoogle(accessToken);
    }
  }, [response]);

  return (
    <ImageButton
      disabled={!request}
      onPress={promptAsync}
      style={styles.buttonStyle}
      source={{uri: images.main_register_google_button}}
    />
  );
}
