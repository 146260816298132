import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedUserUserID } from "@src/ducks/slices/user.slice";
import { ImageButton06 } from "components/button";
import _ from "lodash";
import React, { useContext, useRef } from "react";
import { Image, View } from "react-native";
import { useFetchPost } from "utils/api/hooks";
import { AvatarContext } from "../AvatarProvider";
import ItemList from "./AvatarList";
import useStyles from "./styles.css";

const AvatarList = () => {
  const styles = useStyles();
  const avatarRef = useRef<any>();
  const { onFetchMissions } = useLobbyService();
  const { onUpdateAvatarTier } = useLobbyService();
  const userId = useAppSelector(selectedUserUserID);
  const {loading, runRequest, data} = useFetchPost();
  const { setShowScreen } = useContext(AvatarContext);
  const { baseWidth } = React.useContext(DimensionContext);

  const onChange = () => {
    const active = avatarRef?.current?.getActive();

    if (!_.isEmpty(active)) {
      runRequest("/api/v1/evo/avatar", { userID: userId, avatarID: active.id });
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      const active = avatarRef?.current?.getActive();
      onUpdateAvatarTier({avatarID: active.id});
      onFetchMissions();
      setShowScreen("");
    }
  }, [data]);

  return (
    <View style={styles.v_fullscreen}>
      <View style={[styles.container, {height: baseWidth * 0.4583}]}>
        <Image
          style={styles.i_bg}
          source={{uri: images.bg_transparent}}
          resizeMode="stretch"
        />
        <ItemList ref={avatarRef} baseWidth={baseWidth} />
        <View style={styles.v_bottom}>
          <ImageButton06
            onPress={() => setShowScreen("")}
            source={images["btn-small"]}
            style={[
              styles.btn_back,
              { height: baseWidth * 0.04, width: baseWidth * 0.08 },
            ]}
            label="НАЗАД"
            labelStyle={{fontSize: baseWidth * 0.01}}
          />
          <ImageButton06
            isLoad={loading}
            onPress={onChange}
            style={[
              styles.btn_change,
              { height: baseWidth * 0.04, width: baseWidth * 0.11 },
            ]}
            source={images["btn-big"]}
            label="СМЕНИ АВАТАР"
            labelStyle={{fontSize: baseWidth * 0.01}}
          />
        </View>
      </View>
    </View>
  );
};

export default React.memo(AvatarList);
