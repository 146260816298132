import images from "@assets/images";
import { useAuthService, useSettingsService } from "@src/ducks/hooks";
import { ImageButton06 } from "components/button";
import React from "react";
import Alert from "utils/alert";
import useStyles from "./styles.css";

const Logout = ({ baseWidth, baseHeight }: any) => {
  const styles = useStyles();
  const {onLogout} = useAuthService();
  const { onDeactivatingRequest } = useSettingsService();

  const onDeactivate = () => {
    Alert(
      "Deactivate Account",
      "Are you sure you want to deactivate your account?",
      [
        {
          text: "Okay",
          onPress: () => onDeactivatingRequest(),
        },
      ],
      { cancelable: false }
    );
  };

  return (
    <ImageButton06 
      source={{uri: images["btn-big"]}}
      onPress={onLogout} 
      label="Излез"
      style={{width: baseWidth * 0.531, height: baseHeight * 0.13}} />
  );
};

export default React.memo(Logout);
