import React from "react";
import { Text, TextStyle } from "react-native";
import useStyles from "./styles.css";

type Props = {
  label: string;
  style?: TextStyle;
  onPress: () => void;
}

const TextButton = (props: Props) => {
  const styles = useStyles();

  return (
    <Text
      selectable={false}
      onPress={props.onPress}
      suppressHighlighting={false} 
      style={[styles.text_button, props.style]}>
      {props.label}
    </Text>
  );
};

export default React.memo(TextButton);
