import React, { useContext } from "react";
import { selectedActiveAvatarWB } from "@src/ducks/slices/lobby.slice";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { AvatarContext } from "../AvatarProvider";
import { ImageButton06 } from "components/button";
import { View, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";

const Avatar = () => {
  const styles = useStyles();
  const { setShowScreen } = useContext(AvatarContext);
  const avatar = useAppSelector(selectedActiveAvatarWB);
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={styles.v_container}>
      <View style={styles.v_topdivider} />
      <View style={[styles.v_centerframe, 
        { width: baseWidth * 0.1769, height: baseWidth * 0.2969 }]}>
        <Image
          style={styles.i_avatar_frame}
          source={{uri: images.avatar_frame}}
          resizeMode="stretch"
        />
        <Image
          style={[
            styles.i_avatar_wb,
          ]}
          source={{uri: avatar}}
          resizeMode="stretch"
        />
      </View>
      <ImageButton06
        style={[
          styles.btn_change,
          { height: baseWidth * 0.05, width: baseWidth * 0.15 },
        ]}
        label="СМЕНИ АВАТАР"
        source={images["btn-big"]}
        labelStyle={{fontSize: baseWidth * 0.012}}
        onPress={() => setShowScreen("avatar")}
      />
    </View>
  );
};

export default Avatar;
