import React from "react";
import { SvgProps } from "react-native-svg";

//SVGs
import BGSound from "./bg-sound";

interface IconProps extends SvgProps {
  name: "btn-disclaimer" | "btn-faq" | "btn-logout" | "btn-privacy-policy" | "bg-sound" | "user" |
    "btn-share" | "btn-terms-condition" | "btn-tier-level" | "t-settings" | "bg-settings";
  size?: number;
  stroke?: string;
  fill?: string;
  isActive?: boolean;
}

const SVGSettings = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  case "bg-sound":
    return <BGSound width={width} height={height} {...props} />;
  default:
    return null;
  }
};

export default React.memo(SVGSettings);
