import { all, fork } from "redux-saga/effects";
import authWatcherSaga from "./auth.sagas";
import gmodeWatcherSaga from "./gmode.sagas";
import lobbyWatcherSaga from "./lobby.sagas";
import soundWatcherSaga from "./sound.sagas";
import tierWatcherSaga from "./tier.sagas";

export default function* rootSaga() {
  yield all([
    fork(authWatcherSaga),
    fork(lobbyWatcherSaga),
    fork(gmodeWatcherSaga),
    fork(tierWatcherSaga),
    fork(soundWatcherSaga),
  ]);
}
