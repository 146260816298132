const Shop = [
  {
    amount: 2.99,
    productId: "gwz_2.99_2m_silv",
    currency: "USD",
    silver: 2000000,
    gold: 1,
    chestIcon: "coins1",
    silverIcon: "2m-amount",
    isShop: true,
  },
  {
    amount: 5.99,
    productId: "gwz_5.99_6m_silv",
    currency: "USD",
    silver: 6000000,
    gold: 3,
    chestIcon: "coins2",
    isShop: true,
    silverIcon: "6m-amount",
    bonusGold: "bonus_gold3",
  },
  {
    amount: 10.99,
    productId: "gwz_10.99_10m_silv",
    currency: "USD",
    silver: 10000000,
    gold: 11,
    chestIcon: "coins3",
    silverIcon: "10m-amount",
    bonusGold: "bonus_gold11",
    isShop: true,
  },
  {
    amount: 19.99,
    productId: "gwz_19.99_20m_silv",
    currency: "USD",
    silver: 20000000,
    gold: 20,
    chestIcon: "coins4",
    silverIcon: "20m-amount",
    bonusGold: "bonus_gold20",
    isShop: true,
  },
  {
    amount: 49.99,
    productId: "gwz_49.99_50m_silv",
    currency: "USD",
    silver: 50000000,
    gold: 50,
    chestIcon: "coins5",
    silverIcon: "50m-amount",
    bonusGold: "bonus_gold50",
    isShop: true,
  },
  {
    amount: 74.99,
    productId: "gwz_74.99_75m_silv",
    currency: "USD",
    silver: 75000000,
    gold: 75,
    chestIcon: "coins5",
    silverIcon: "75m-amount",
    bonusGold: "bonus_gold75",
    isShop: true,
  },
];

const SubSciption = [
  {
    name: "PACKAGE 1",
    amount: 21.99,
    currency: "USD",
    silver: 2000000,
    gold: 22,
    isSubscription: true,
    productId: "gwz_21.99_14d_package_1",
    description:
      "2,000,000 Silver Coins Daily for 14 days\n\nTotal 14 Day Value 24,000,000 Silver Coins",
    packIcon: "one",
    chestIcon: "pack_chest1",
    silverIcon: "2m-amount",
    bonusIcon: "bonus_gold22",
  },
  {
    name: "PACKAGE 2",
    amount: 54.99,
    currency: "USD",
    silver: 5000000,
    gold: 55,
    isSubscription: true,
    productId: "gwz_54.99_14d_package_2",
    description:
      "5,000,000 Silver Coins Daily for 14 days\n\nTotal 14 Day Value 70,000,000 Silver Coins",
    packIcon: "two",
    chestIcon: "pack_chest2",
    silverIcon: "5m-amount",
    bonusIcon: "bonus_gold55",
  },
];

const SubscriptionProduct: any = ["gwz_21.99_14d_package_1", "gwz_54.99_14d_package_2"];

export default {
  Shop,
  SubSciption,
  SubscriptionProduct,
};
