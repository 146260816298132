import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton02 } from "@src/components/button";
import { Loading01 } from "@src/components/loading";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useAuthService } from "@src/ducks/hooks";
import { selectAuthLoggingIn } from "@src/ducks/slices/auth.slice";
import { NavigationScreenProps } from "@src/navigators/NavigatorTypes";
import React from "react";
import { Image, Text, View } from "react-native";
import Facebook from "./Facebook";
import Google from "./Google";
import useStyles from "./styles.css";

const SignOption = () => {
  const styles = useStyles();
  const { onResetLoading } = useAuthService();
  const { baseWidth } = React.useContext(DimensionContext);
  const isLoggingIn = useAppSelector(selectAuthLoggingIn);
  const navigation = useNavigation<NavigationScreenProps<"SignIn"| "SignUp">>();

  React.useEffect(() => {
    onResetLoading();
  }, []);

  return (
    <View style={styles.container}>
      <View style={[styles.bodyStyle, { height: baseWidth * 0.24}]}>
        <Text style={[styles.t_signup_using, { fontSize: baseWidth * 0.024}]}>ВЛЕЗ В ПРОФИЛА СИ, ИЗПОЛЗВАЙКИ:</Text>
        <View style={styles.viewCenter}>
          <View style={[styles.v_social_login, { height: baseWidth * 0.052}]}>
            <Google />
            <Facebook />
          </View>
          <View style={[styles.separator, { height: baseWidth * 0.03}]}>
            <View style={styles.divider} />
            <Text style={[styles.txtOrstyle, { fontSize: baseWidth * 0.011}]}>Or</Text>
            <View style={styles.divider} />
          </View>
          <View style={[styles.v_social_login, { height: baseWidth * 0.052}]}>
            <ImageButton02
              style={styles.btn_reg_email}
              onPress={() => navigation.navigate("SignUp")} >
              <Image
                style={styles.imageStyle}
                source={{uri: images.main_register_button}}
                resizeMode="stretch"
              />
            </ImageButton02>
            <ImageButton02
              style={styles.btn_reg_email}
              onPress={() => navigation.navigate("SignIn")} >
              <Image
                style={styles.imageStyle}
                source={{uri: images.main_login_button}}
                resizeMode="stretch"
              />
            </ImageButton02>
          </View>
        </View>
        <Loading01 isLoading={isLoggingIn} />
      </View>
      <Image
        source={{uri: images["referee"]}}
        style={styles.i_referee}
        resizeMode="stretch"
      />
      <Text style={[styles.t_terms_condition, { fontSize: baseWidth * 0.012, lineHeight: baseWidth * 0.020}]}>
        Продължавайки към игрите, ти се съгласяваш, че си на 18+ години, прочел си и си се съгласил с нашите Общи условия и Политики за сигурност. 
        Game Zone е Социална платформа за игри и по никакъв начин не предоставя възможност за спечелване на реални парични средства. 
      </Text>
    </View>
  );
};

export default SignOption;
