import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const PurchaseConfirmation = (props: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth } = React.useContext(DimensionContext);

  const onCance = () => { 
    if(props.onCancel){
      props.onCancel();
      return;
    }
    navigation.goBack();
  }

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.center_view,
          { width: baseWidth * 0.52, height: baseWidth * 0.19 },
        ]}
      >
        <Image
          style={styles.i_background}
          source={{uri: images["bg-notice"]}}
          resizeMode="stretch"
        />
        <View style={styles.v_body}>
          <Text style={[styles.t_notes, {fontSize: baseWidth * 0.022, lineHeight: baseWidth * 0.018}]}>
          МОЛЯ, ПОТВЪРДЕТЕ
          </Text>
          <View style={styles.v_actions}>
            <ImageButton06
              onPress={props.onConfirm}
              style={styles.btn_confirm}
              source={images["btn-confirm"]}
            />
            <View style={styles.btn_divider} />
            <ImageButton06
              onPress={onCance}
              style={styles.btn_confirm}
              source={images["btn-cancel"]}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default PurchaseConfirmation;
