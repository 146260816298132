import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useErrorService } from "@src/ducks/hooks";
import { ImageButton06 } from "components/button";
import _ from "lodash";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const PopNotice = () => {
  const styles = useStyles();
  const { baseWidth } = React.useContext(DimensionContext);
  const { errorMessage, onResetErrorMessage } = useErrorService();

  if(_.isEmpty(errorMessage)){
    return null;
  }

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.center_view,
          { width: baseWidth * 0.57, height: baseWidth * 0.19 },
        ]}
      >
        <Image
          style={styles.i_background}
          source={{uri: images["bg-notice"]}}
          resizeMode="stretch"
        />
        <View style={styles.v_notice}>
          <Image
            style={styles.i_background}
            source={{uri: images["notice"]}}
            resizeMode="stretch"
          />
          <Image
            style={styles.i_exclamation}
            source={{uri: images["exclamation"]}}
            resizeMode="stretch"
          />
        </View>
        <View style={styles.v_body}>
          <Text style={[styles.t_notes, {fontSize: baseWidth * 0.013, lineHeight: baseWidth * 0.02}]}>
            {errorMessage}
          </Text>
          <ImageButton06
            label="Разбрах"
            style={styles.btn_ok}
            source={images["btn-big"]}
            onPress={onResetErrorMessage}
            labelStyle={{fontSize: baseWidth * 0.012}}
          />
        </View>
      </View>
    </View>
  );
};

export default PopNotice;
