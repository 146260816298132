import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useForgotService } from "@src/ducks/hooks";
import { ImageButton, ImageButton06 } from "components/button";
import { TextInput01 } from "components/textinput";
import React, { useEffect, useState } from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const EnterEmail = () => {
  const styles = useStyles();
  const navigation = useNavigation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const {onForgot, isLoading}= useForgotService();
  const { baseWidth, maxHeight } = React.useContext(DimensionContext);

  const onSubmit = () => {
    if (!email) {
      setError("Required.");
    } else {
      onForgot(email);
    }
  };

  useEffect(() => {
    setError("");
  }, [email]);

  return (
    <View style={[styles.bodyStyle, { height: maxHeight * 0.5}]}>
      <Image
        source={{uri: images.bg_transparent}}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <Image
        source={{ uri: images["gamezone-logo-horizontal"]}}
        style={styles.smallLogoContainer}
        resizeMode="stretch"
      />
      <ImageButton
        onPress={() => navigation.goBack()}
        style={styles.btnClose}
        source={{uri: images["btn-close-red"]}}
      />
      <View style={styles.viewEmail}>
        <View style={[styles.viewForgotStyle]}>
          <Image
            resizeMode="contain"
            source={{uri: images["text-forgot"]}}
            style={styles.forgotTextStyle}
          />
        </View>
        <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.014}]}>Въведи имейла си</Text>
        <TextInput01
          value={email}
          error={error}
          placeholder="Имейл"
          secureTextEntry={false}
          onChangeText={setEmail}
          disableFullscreenUI={true}
          inputStyle={[styles.textAlign, { fontSize: baseWidth * 0.014}]}
          containerStyle={styles.inputEmailStyle}
        />
        <ImageButton06
          label="Влез"
          onPress={onSubmit}
          isLoading={isLoading}
          style={[styles.btnSubmit]}
          source={images["btn-big"]}
          labelStyle={{fontSize: baseWidth * 0.013}}
        />
      </View>
    </View>
  );
};

export default EnterEmail;
