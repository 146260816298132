import images, { SplashAssets } from "@assets/images";
import { Loading02 } from "components/loading";
import Constants from "expo-constants";
import React from "react";
import { isMobile } from "react-device-detect";
import { Dimensions, Image, Text, View, useWindowDimensions } from "react-native";
import useCacheImage from "utils/cache-helper";
import useStyles from "./styles.css";

const VERSION = `Версия ${Constants.expoConfig?.version}`;

const SCREEN_WIDTH = Dimensions.get("screen").width;
const SCREEN_HEIGHT = Dimensions.get("screen").height;
const minHeight = Math.min(SCREEN_WIDTH, SCREEN_HEIGHT);

const Splash = () => {
  const styles = useStyles();
  const appIsReady = useCacheImage(SplashAssets);
  const { width, height } = useWindowDimensions();
  const maxHeight = React.useMemo(() => {
    return width * 0.55;
  },[width]);

  if(!appIsReady){
    return <Loading02 />;
  }

  return (
    <View style={styles.container}>
      <View  style={[styles.bodyStyle, { 
        minHeight: isMobile ? minHeight : 0, 
        width, height, maxHeight: maxHeight
      }]}>
        <Image style={styles.i_background} source={{uri: images["bg-splash"]}} resizeMode="stretch" />
        <Text style={styles.t_version}>{VERSION}</Text>
      </View>
    </View>
  );
};

export default Splash;
