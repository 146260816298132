import images from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import useSoundService from "@src/ducks/hooks/useSoundService";
import { selectSoundEnabled } from "@src/ducks/slices/sound.slice";
import { colors } from "@src/theme";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, TouchableWithoutFeedback } from "react-native";
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

const SwitchItem = ({ size }: any) => {
  const iconSize = size / 1.6;
  const animated = useSharedValue(0);
  const [width, setWidth] = useState(0);
  const soundEnabled = useAppSelector(selectSoundEnabled);
  const { bgsoundEnable, bgsoundDisable } = useSoundService();

  const onPress = () => {
    if(soundEnabled){
      bgsoundDisable();
    }else{
      bgsoundEnable();
    }
  };

  const onLayout = (e: any) => {
    setWidth(e.nativeEvent.layout.width);
  };

  const animateSound = () => {
    if (!width) {
      return null;
    }

    const toValue = soundEnabled ? 0 : -(width - size);

    animated.value = withTiming(toValue, { duration: 300 });
  };

  const backgroundStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        animated.value,
        [-(width - size), 0],
        [colors.black4, colors.green1]
      ),
    };
  },[width, size]);

  const animateStyle = useAnimatedStyle(() => {
    return {
      transform: [{translateX: animated.value}],
    };
  }, []);

  useEffect(() => {
    animateSound();
  }, [soundEnabled, width]);

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <Animated.View onLayout={onLayout} style={[styles.container, backgroundStyle]}>
        <Image style={{ width: iconSize, height: iconSize, position: "absolute", left: size * 0.22 }}
          source={{uri: images["sound-check"]}} />
        <Image style={{ width: iconSize, height: iconSize, position: "absolute", right: size * 0.22 }}
          source={{uri: images["sound-cross"]}} />
        <Animated.View
          style={[
            styles.circleStyle,
            { width: size, height: size },
            animateStyle,
          ]}
        />
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "88%",
    height: "69%",
    borderRadius: 25,
    alignItems: "flex-end",
    justifyContent: "center",
    alignSelf: "center",
    marginBottom: "3%",
    backgroundColor: colors.green1,
  },
  circleStyle: {
    width: "50%",
    height: "120%",
    borderRadius: 100,
    position: "absolute",
    backgroundColor: colors.white,
  },
});

export default SwitchItem;
