import { CreateResponsiveStyle } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "50%",
      height: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    goldBar: {
      width: "100%",
      height: "100%",
      zIndex: 1
    },
    goldText: {
      zIndex: 2,
      right: "33%",
      marginTop: "5%",
      fontSize: 10,
      color: colors.white,
      position: "absolute",
      fontFamily: "Roboto-Medium",
    },

    // DROPDOWN
    i_dropdown: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_body: {width: "100%", height: "100%", position: "absolute", alignItems: "center"},
    v_divider: {height: "9%"},
    v_divider2: {height: "5%"},

    // ITEM GOLD
    v_item_gold: {flexDirection: "row", alignItems:"center", height: "20%", width: "80%"},
    v_container_shop: { alignItems:"center", height: "28%", width: "94.8%", overflow: "hidden" },
    v_item_shop: {flexDirection: "row", alignItems:"center", height: "100%", width: "100%", },
    i_gwz_shop: {
      width: "20%",
      height: "90%",
    },
    i_gold: {
      width: "20%",
      height: "90%",
    },
    t_gold_amount: {flex: 1, 
      fontFamily: "Roboto-Bold", color: "white", 
      textAlign: "center", marginRight: "10%"},

    btn_switch: {
      zIndex: 10,
      bottom: 0,
      alignSelf: "center",
      position: "absolute",
      flexDirection: "row", 
      alignItems:"center",
      backgroundColor: "rgba(0,0,0,0.5)",
      height: "13%", width: "42%", marginLeft: "1%"},

    // COMING SOON
    i_warning: {
      width: "22%",
      height: "70%",
    },
    v_coming_soon: {
      position: "absolute", flexDirection: "row", 
      alignItems:"center", height: "100%", width: "100%", 
      paddingHorizontal: "5%", backgroundColor: "black"},
  },
);

export default useStyles;
