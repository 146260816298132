// DUCKS pattern
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";

export interface InitialState {
  loading: boolean;
  hasSettingsOpen: boolean;
  hasProfileOpen: boolean;
}

export const initialState: InitialState = {
  loading: false,
  hasSettingsOpen: false,
  hasProfileOpen: false,
} as InitialState;

// Slice
export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    hasSettingsOpen: (state, action) => {
      state.hasSettingsOpen = action.payload;
    },
    hasProfileOpen: (state, action) => {
      state.hasProfileOpen = action.payload;
    },
    deactivateAccount: (state) => {
      state.loading = true;
    },
  },
});

// Actions
export const settingsActions = {
  hasSettingsOpen: settingsSlice.actions.hasSettingsOpen,
  hasProfileOpen: settingsSlice.actions.hasProfileOpen,
  deactivateAccount: settingsSlice.actions.deactivateAccount,
};

// Selectors
export const selectedSettingsStatus = (state: RootState) => state.settings.hasSettingsOpen;
export const selectedProfileStatus = (state: RootState) => state.settings.hasProfileOpen;
export const selectedDeactivating = (state: RootState) => state.settings.loading;
// export const selectedNotificationsUnread = createSelector(
//   (state: RootState) => state.settings.notifications,
//   (state) => state.find((item: any) => item?.status === "UNREAD")
// );

// Reducer
export default settingsSlice.reducer;
