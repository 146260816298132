import { useNavigation, useRoute } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedGModeURL } from "@src/ducks/slices/gmode.slice";
import { selectedUserCoins } from "@src/ducks/slices/user.slice";
import React from "react";
import { View } from "react-native";
import { GModeContext } from "./provider";
import "./style.css";
import useStyles from "./styles.css";

const GameView = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const gameTypeID = route?.params?.gameTypeID;
  const gameLink = useAppSelector(selectedGModeURL);
  const { GOLD } = useAppSelector(selectedUserCoins);
  const { onSetSMdata } = React.useContext(GModeContext);

  React.useEffect(() => {
    const handleMessage = (event: any) => {
      const iframe: any = document.getElementById("game-iframe"); // Replace with your iframe's ID
      if (event.source === iframe.contentWindow) {
        const data = event.data || {};
        console.log("datadatadata", data);

        if(["close_window", "exit_game", "closeGame"].includes(data.event)){
          navigation.goBack();
        }else if(typeof data === "string" && ["close_window", "exit_game", "closeGame"].includes(data)){
          navigation.goBack();
        }

        if(data.message === "app.state"){
          if(data.state && data.state?.fullscreen){
            const document: any = window.document;
            const enabled = Boolean(document.fullscreenElement);

            if(enabled){
              document
                .exitFullscreen()
                .then(() => console.log("Document Exited from Full screen mode"))
                .catch((err: any) => console.error(err));
            }else{
              document.body.requestFullscreen();
            }
          }
        }

        if(gameTypeID === 7){
          onSetSMdata(data.data);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [GOLD, gameTypeID]);

  return (
    <View style={styles.v_iframe_wrapper}>
      <iframe
        src={gameLink}
        className="iframe"
        width="100%"
        height="100%"
        id="game-iframe"
        frameBorder={0}
        // allowfullscreen={false}
        allowFullScreen={false}
        // sandbox={['allow-same-origin', 'allow-scripts']}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        // style={styles.v_gameview}
        // style="position: fixed;"
      />
    </View>
  );
};

export default React.memo(GameView);
