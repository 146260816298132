import { colors } from "@src/theme";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      zIndex: 6,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: colors.translucent,
    },
    center_view: {
      width: "60%",
      height: "76%",
      alignItems: "center",
      justifyContent: "center",
    },
    v_body: {
      zIndex: 2,
      gap: 5,
      width: "94%",
      height: "70%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    i_referee: {width: "19%", height: "117%", 
      position: "absolute", left: "-5%",
      transform: [{rotateY: "180deg"}]},
    v_notice: {position: "absolute", top: "-7%", width: "26%", height: "24%"},
    i_exclamation: {
      width: "23%",
      height: "73%",
      right: "-8%",
      top: "-8%",
      position: "absolute",
    },
  
    t_notes: { 
      marginTop: "1%",
      fontFamily: "Roboto-Bold", 
      textAlign: "center", 
      color: colors.white, 
      width: "70%",
    },
    t_notes2: { 
      textAlign: "left", 
      marginTop: "4%", width: "80%",
      fontFamily: "Roboto", 
      color: colors.white,
    },
    
    btn_ok: {
      zIndex: 2,
      width: "22%",
      marginTop: "3%", 
      height: "30%"
    },
    btn_close: {
      zIndex: 5,
      width: "6%",
      height: "10%",
      top: "10%",
      right: "5%",
      position: "absolute",
    },
    btn_close2: {
      zIndex: 5,
      width: "8%",
      height: "13%",
      top: "10%",
      right: "4%",
      position: "absolute",
    },

    // GOLD EXCHANGE
    gold_echange_close: {
      zIndex: 5,
      width: "6%",
      height: "10%",
      top: "10%",
      right: "5%",
      position: "absolute",
    },

    // ACCESS DENIED
    v_access_denied: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
      left: "9%",
      justifyContent: "flex-start",
    },
    i_access_denied_exclamation: {
      width: "7.2%",
      height: "34%",
    },
    t_access_level: { 
      width: "70%",
      left: "2%",
      fontFamily: "Roboto-Bold", 
      textAlign: "center", 
      color: colors.white,
    },
    access_denied_close: {
      zIndex: 5,
      width: "6%",
      height: "10%",
      top: "20%",
      right: "20%",
      position: "absolute",
    },

    // INSUFICIENT COINS
    v_buttons: {
      flexDirection: "row", alignItems: "center", 
      justifyContent: "center", 
      // marginTop: "5%", 
      width: "100%", 
      height: "32%",
    },
    btn_style: {
      width: "24%",
      height: "100%",
    },

    // PURCHASE CONFIRMATION
    v_actions: {
      marginTop: "4%",
      flexDirection: "row",
      height: "25%",
      width: "65%",
      alignItems: "center",
      justifyContent: "center",
    },
    btn_divider: {width: '7%'},
    btn_confirm: {
      width: "33%",
      height: "100%"
    },
  }
);

export default useStyles;
